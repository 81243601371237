import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import SearchBar from '@/components/atoms/SearchBar'
import SelectField from '@/components/atoms/SelectField'
import theme from '@/theme'
import { getTime } from '@/utils/nendo'
import { FormControl, FormControlLabel, FormControlLabelProps, Radio, RadioGroup } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyle = makeStyles({
    container: {
        width: '100%',
        padding: '13px 15px',
        backgroundColor: theme.colors.expandBackground,
    },
    containerAnalysis: {
        backgroundColor: theme.colors.primaryN,
    },
    target: {
        width: '100%',
        marginLeft: 10,
        fontWeight: 300,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    targetActive: {
        marginLeft: 0,
        marginTop: 10,
    },
    selectWrapper: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
    },
    expandBtn: {},
    flex: { display: 'flex', alignItems: 'center', columnGap: 10, flexWrap: 'wrap' },
    radioGroup: {
        width: 382,
        borderRight: '2px solid #fff',
    },
})

interface OptionType {
    id: number
    value: string | number
}

interface TSelectorGroup {
    typeData?: number
    year?: number
    typeID?: number
    search?: string
    optionsID: OptionType[]
    optionsYear: OptionType[]
    onFilterChange: (value: number) => void
    onFilterYearChange: (value: number) => void
    onFilterTypeData: (value: number) => void
    emissionFactorTableId: number
    yearFilter: number
    valueSearch: string
    setValueSearch: (value: React.SetStateAction<string>) => void
    onCleanFilter: () => void
}

const radioOptions = [
    { id: 1, value: 0, label: '全て' },
    { id: 2, value: 1, label: '温対法該当' },
    { id: 3, value: 2, label: '省エネ法該当' },
]

function SelectorGroup(props: TSelectorGroup) {
    const {
        typeData,
        optionsID,
        onFilterChange,
        emissionFactorTableId,
        optionsYear,
        onFilterYearChange,
        onFilterTypeData,
        yearFilter,
        valueSearch,
        setValueSearch,
        onCleanFilter,
    } = props
    const classes = useStyle()

    const handleChangeTypeData = (idType: number) => {
        const { year: currentYear } = getTime()
        if (idType !== 0) onFilterYearChange(yearFilter === 0 ? currentYear : yearFilter)
        else onFilterYearChange(0)
        onFilterTypeData(idType)
    }

    return (
        <div className={`${classes.container}`}>
            <div className={classes.selectWrapper}>
                <div className={classes.radioGroup}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            value={typeData}
                            onChange={(e) => handleChangeTypeData(Number(e.target.value) as number)}
                        >
                            {radioOptions.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    value={option.value}
                                    control={<Radio color="primary" />}
                                    label={<span style={{ fontSize: 14 }}>{option.label}</span>}
                                    style={{ marginLeft: 0 }}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={classes.flex}>
                    <div style={{ width: 224, marginLeft: 15, marginTop: 5, marginBottom: 5 }}>
                        {optionsID && onFilterYearChange && (
                            <SelectField
                                size="small"
                                fullWidth={true}
                                value={yearFilter}
                                onChange={(e) => onFilterYearChange(e.target.value as number)}
                                menuItems={optionsYear}
                                sx={{ fontSize: 16 }}
                            />
                        )}
                    </div>
                    <div style={{ width: 320, marginLeft: 15, marginTop: 5, marginBottom: 5 }}>
                        {optionsID && onFilterChange && (
                            <SelectField
                                size="small"
                                fullWidth={true}
                                value={emissionFactorTableId}
                                onChange={(e) => onFilterChange(e.target.value as number)}
                                menuItems={optionsID}
                                sx={{ fontSize: 16 }}
                            />
                        )}
                    </div>
                    <div style={{ marginLeft: 15, marginTop: 5, marginBottom: 5 }}>
                        <SearchBar
                            value={valueSearch}
                            setValue={setValueSearch}
                            width={320}
                            placeholder="名称で検索"
                            autoComplete="off"
                            fontSize={16}
                        />
                    </div>
                    <div style={{ marginLeft: 15, marginTop: 5, marginBottom: 5 }}>
                        <PrimaryButton style={{ width: 96, height: 38, fontSize: 16 }} onClick={() => onCleanFilter()}>
                            リセット
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectorGroup
