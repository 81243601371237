import CaretDownIcon from '@/components/atoms/Icon/CaretDown'
import EditIcon from '@/components/atoms/Icon/EditIcon'
import NoRowsOverlay from '@/components/atoms/NoRowOverlay'
import Color from '@/enums/Color'
import { EmissionFactor } from '@/openapi'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback } from 'react'

interface TEmissionFactorIdTable {
    listEmissionFactorTableId: EmissionFactor[]
    sortMode: 'asc' | 'desc' | 'default'
    sortId: number | null
    typeDataFilter: number
    loading: boolean
    handleSort: (id: number) => void
    onEditClick: (emissionFactor: EmissionFactor) => void
}

const useStyle = makeStyles({
    tableRow: {
        '&:nth-child(even)': {
            backgroundColor: '#FBFBFB',
        },
        color: '#222222',
    },
    bodyCell: {
        fontSize: 14,
        textAlign: 'center',
        border: '1px dashed #D5D5D5',
        fontWeight: 400,
        color: '#222222',
    },
    headerCell: {
        backgroundColor: Color.lightGray,
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'center',
        border: '1px dashed #D5D5D5',
        color: '#222222',
    },
    sortBtn: {
        '&  svg > path': {
            fill: '#B7B7B7',
        },
    },
})

function EmissionFactorIdTable(props: TEmissionFactorIdTable) {
    const { listEmissionFactorTableId, sortMode, sortId, handleSort, typeDataFilter, loading } = props
    const classes = useStyle()

    const showColumsSecond = () => {
        const column = {
            id: 2,
            field: 'year',
            headerName: '適用年',
            disableColumnMenu: true,
            minWidth: 120,
            sort: false,
            col: 1,
        }
        switch (typeDataFilter) {
            case 1:
                column.field = 'project'
                column.headerName = '温対法項目'
                column.minWidth = 495
                column.sort = true
                break
            case 2:
                column.field = 'energy'
                column.headerName = '省エネ法 エネルギーの種類'
                column.minWidth = 495
                column.col = 3
                break
        }
        return [column]
    }

    const columns = [
        {
            id: 1,
            field: 'index',
            headerName: '番号',
            minWidth: 92,
            col: 1,
        },
        ...showColumsSecond(),
        { id: 3, field: 'emission_factor_table_id', headerName: '種別ID', minWidth: 108, sort: true, col: 1 },
        { id: 4, field: 'id', headerName: 'No.', minWidth: 110, align: 'center' },
        { id: 5, field: 'name', headerName: '名称', minWidth: typeDataFilter === 0 ? 750 : 438, flex: 2, col: 1 },

        { id: 6, field: 'value', headerName: '排出係数', minWidth: 170, col: 1 },
        { id: 7, field: 'unit', headerName: '単位', disableColumnMenu: true, minWidth: 100, align: 'center', col: 1 },

        { id: 8, field: 'actions', headerName: '編集', minWidth: 70, col: 1 },
    ]

    const showTableCellSecond = (item: EmissionFactor) => {
        if (typeDataFilter === 2) {
            const energy_saving_parent = item?.energy_saving_parent
            const length_energy_saving = energy_saving_parent?.length || 0
            return (
                <>
                    <TableCell style={{ textAlign: 'left' }} className={classes.bodyCell}>
                        {energy_saving_parent?.[0]}
                    </TableCell>
                    <TableCell
                        style={{ textAlign: 'left' }}
                        colSpan={length_energy_saving > 2 ? 1 : 2}
                        className={classes.bodyCell}
                    >
                        {energy_saving_parent?.[1]}
                    </TableCell>
                    {energy_saving_parent?.[2] && (
                        <TableCell style={{ textAlign: 'left' }} className={classes.bodyCell}>
                            {energy_saving_parent?.[2]}
                        </TableCell>
                    )}
                </>
            )
        }
        return (
            <TableCell style={{ textAlign: typeDataFilter === 0 ? 'center' : 'left' }} className={classes.bodyCell}>
                {typeDataFilter === 0 ? item?.year : item?.anti_warming_name}
            </TableCell>
        )
    }

    const renderList = useCallback(
        () =>
            listEmissionFactorTableId.map((item, index) => {
                return (
                    <TableRow className={classes.tableRow} key={item.id}>
                        <TableCell className={classes.bodyCell}>{index + 1}</TableCell>
                        {showTableCellSecond(item)}
                        <TableCell className={classes.bodyCell}>{item.emission_factor_table_id}</TableCell>
                        <TableCell className={classes.bodyCell}>{item.id}</TableCell>
                        <TableCell style={{ textAlign: 'left', paddingLeft: 20 }} className={classes.bodyCell}>
                            {item.name}
                        </TableCell>
                        <TableCell className={classes.bodyCell}>
                            {Number.parseFloat(String(item.value)).toFixed(10)}
                        </TableCell>
                        <TableCell className={classes.bodyCell}>{item.unit}</TableCell>
                        <TableCell className={classes.bodyCell}>
                            <div
                                className="action-btn fw-600 fz-12 cursor-pointer"
                                onClick={() => props.onEditClick(item)}
                            >
                                <EditIcon />
                            </div>
                        </TableCell>
                    </TableRow>
                )
            }),
        [listEmissionFactorTableId],
    )

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((h, i) => (
                            <TableCell
                                key={i}
                                style={{ minWidth: h.minWidth, padding: '0 10px 10px 10px' }}
                                className={classes.headerCell}
                                colSpan={h.col}
                            >
                                <div style={{ marginTop: h.sort ? 20 : 'unset' }}>{h.headerName}</div>
                                {h.sort ? (
                                    <IconButton
                                        style={{ padding: 0 }}
                                        onClick={() => handleSort(h.id)}
                                        className={classes.sortBtn}
                                    >
                                        <CaretDownIcon
                                            direction={sortMode === 'asc' && sortId === h.id ? 'up' : 'down'}
                                        />
                                    </IconButton>
                                ) : null}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <TableCell style={{ borderBottom: 'none' }} align="left" colSpan={8}>
                                loading...
                            </TableCell>
                        </TableRow>
                    ) : Array.isArray(listEmissionFactorTableId) && listEmissionFactorTableId.length > 0 ? (
                        renderList()
                    ) : (
                        <TableRow>
                            <TableCell align="center" colSpan={8}>
                                <NoRowsOverlay />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default EmissionFactorIdTable
