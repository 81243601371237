import { checkDirection, TDirection } from '@/utils/iconHelper'
import React from 'react'

export default function CaretDownIcon({ direction = 'down' }: TDirection) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="currentColor"
            className="bi bi-caret-up-fill"
            viewBox="0 0 16 16"
            style={{
                rotate: `${checkDirection(direction)}deg`,
                transition: 'rotate 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            }}
        >
            <path
                d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                fill="#EAF0F3"
            />
        </svg>
    )
}
