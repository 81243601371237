import theme from '@/theme'
import { FormHelperText, FormHelperTextProps } from '@mui/material'
import React, { CSSProperties } from 'react'

export default function ErrorText({ text, className, sx }: { text: string; className?: string; sx?: CSSProperties }) {
    return (
        <FormHelperText className={className} sx={{ ...sx, fontFamily: theme.typography.fontFamily, color: '#d32f2f' }}>
            {text}
        </FormHelperText>
    )
}
