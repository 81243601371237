import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import SelectField from '@/components/atoms/SelectField'
import Space from '@/components/atoms/Space'
import CustomPagination2 from '@/components/molecules/Pagination/Pagination2'
import SelectorGroup from '@/components/molecules/SelectorGroup/emissionFactorIdSelect'
import EmissionFactorIdTable from '@/components/molecules/tables/emissionFactorId'
import { withLayout } from '@/components/template/Layout'
import { YEAR_OPTIONS } from '@/constant/options'
import useDebounce from '@/hooks/useDebounce'
import useWindowSize from '@/hooks/useWindowSize'
import { EmissionFactor } from '@/openapi'
import { TAllFormValues } from '@/react-hook-form/types'
import { routes } from '@/routes'
import { AppDispatch } from '@/store'
import {
    IOptions,
    emissionStore,
    getEmissionFactorTableID,
    getEmissionFactorTables,
    setEmissionFactor,
} from '@/store/slices/emissionSlice'
import { systemStore } from '@/store/slices/systemSlice'
import { getTime } from '@/utils/nendo'
import { Box, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const limitOptions = [
    { id: 20, value: '20' },
    { id: 50, value: '50' },
    { id: 100, value: '100' },
]

const useStyle = makeStyles({
    tableSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 400,
        fontSize: 14,
        '& span': {
            fontSize: 14,
        },
    },
    divPagination: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'center',
    },
})

function EmissionFactorList() {
    const dispatch = useDispatch<AppDispatch>()
    const classes = useStyle()
    const [page, setPage] = useState<number>(0)
    const [limit, setLimit] = useState<number>(20)
    const [yearFilter, setYearFilter] = useState<number>(0)
    const [typeDataFilter, setTypeDataFilter] = useState<number>(0)
    const [sortMode, setSortMode] = useState<'asc' | 'desc' | 'default'>('default')
    const [sortId, setSortId] = useState<number | null>(null)
    const groupFilterRef = useRef<HTMLDivElement>(null)
    const heightGroupFilter = Number(groupFilterRef?.current?.clientHeight)
    useWindowSize() // help re-render when change size browser

    const [searchTerm, setSearchTerm] = useState<string>('')
    const searchContent = useDebounce<string>(searchTerm, 500, () => setPage(0))
    const [emissionFactorTableId, handleFilterEmissionChange] = useState<number>(0)
    // mock data
    const { listEmissionFactorTable, listEmissionFactorTableId, totalFactorTableId, reUpdate, loading } =
        useSelector(emissionStore)
    const { expandedMenu } = useSelector(systemStore)

    const sortData = useMemo(() => {
        const data = [...listEmissionFactorTableId]
        if (sortMode === 'asc') {
            switch (sortId) {
                case 2:
                    data.sort((a, b) => Number(a?.anti_warming_id) - Number(b?.anti_warming_id))
                    break
                case 3:
                    data.sort((a, b) => Number(a?.emission_factor_table_id) - Number(b?.emission_factor_table_id))
                    break
                default:
                    break
            }
        } else {
            switch (sortId) {
                case 2:
                    data.sort((a, b) => Number(b?.anti_warming_id) - Number(a?.anti_warming_id))
                    break
                case 3:
                    data.sort((a, b) => Number(b?.emission_factor_table_id) - Number(a?.emission_factor_table_id))
                    break
                default:
                    break
            }
        }
        return data
    }, [sortMode, sortId, listEmissionFactorTableId])

    const handleSort = (id: number) => {
        setSortId(id)
        if (['default', 'desc'].includes(sortMode)) {
            setSortMode('asc')
        }
        if (sortMode === 'asc') {
            setSortMode('desc')
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const params: IOptions = {
                search: searchContent.trim(),
                offset: page * limit,
                limit: limit,
                emissionFactorTableId: +emissionFactorTableId || undefined,
                year: +yearFilter || undefined,
                type: undefined,
            }
            if (typeDataFilter !== 0) {
                params.type = typeDataFilter === 1 ? 'anti_warming' : 'energy_saving'
            }
            await dispatch(getEmissionFactorTableID(params))
        }
        fetchData().catch((err) => {})
    }, [page, limit, reUpdate, searchContent, emissionFactorTableId, yearFilter, typeDataFilter])

    useEffect(() => {
        const fetchData = async () => {
            const paramsTables = {
                search: '',
                offset: 0,
                limit: 1000,
            }
            await dispatch(getEmissionFactorTables(paramsTables))
        }
        fetchData().catch((err) => {})
    }, [])
    const listFilters = useMemo(() => {
        return listEmissionFactorTable.map((i) => {
            return {
                id: i.id,
                value: `${i.id} - ${i.name}`,
            }
        })
    }, [listEmissionFactorTable])

    const onSearchChange = (value: React.SetStateAction<string>) => {
        setSearchTerm(value)
    }

    const onFilterChange = async (value: number) => {
        setPage(0)
        handleFilterEmissionChange(value)
    }
    const onFilterYearChange = async (value: number) => {
        setPage(0)
        setYearFilter(value)
    }
    const onFilterTypeData = async (value: number) => {
        setPage(0)
        setTypeDataFilter(value)
        setSortMode('default')
        setSortId(null)
    }
    const onCleanFilter = async () => {
        const { year: currentYear } = getTime()
        setYearFilter(typeDataFilter === 0 ? 0 : currentYear)
        setSearchTerm('')
        handleFilterEmissionChange(0)
    }
    const handleEditClick = (params: EmissionFactor) => {
        const { anti_warming_id, energy_saving_id, emission_factor_table_id, year, energy_saving_parent, ...values } =
            params
        const { year: currentYear } = getTime()
        const emissionFactor: TAllFormValues['addEditEmissionId'] = {
            ...values,
            year: year || currentYear,
            emission_factor_table_id: emission_factor_table_id || 1,
            anti_warming: anti_warming_id ? 'antiWarming' : 'antiWarmingNone',
            anti_warming_id: anti_warming_id || 0,
            energy_saving: energy_saving_id ? 'energySaving' : 'energySavingNone',
            energy_saving_id: energy_saving_id || 0,
        }
        dispatch(setEmissionFactor(emissionFactor))
        navigate(routes.addEditEmissionFactor)
    }

    return (
        <div style={{ color: '#222222' }} className="companies-management-wrapper">
            <div
                style={{
                    position: 'fixed',
                    zIndex: 1,
                    top: 70,
                    width: `calc(100% - ${expandedMenu ? 300 : 120}px)`,
                    transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                }}
                ref={groupFilterRef}
            >
                <Space />
                <div style={{ display: 'flex', justifyContent: 'right', width: '100%', backgroundColor: 'white' }}>
                    <PrimaryButton
                        style={{
                            width: 176,
                            height: 38,
                            fontSize: 16,
                        }}
                        size="small"
                        onClick={() => {
                            navigate(routes.addEditEmissionFactor)
                        }}
                    >
                        係数を追加する
                    </PrimaryButton>
                </div>
                <Space />
                <SelectorGroup
                    optionsID={[{ id: 0, value: '種別IDを選択' }, ...listFilters]}
                    optionsYear={[{ id: 0, value: '適用年を選択' }, ...YEAR_OPTIONS]}
                    onFilterChange={onFilterChange}
                    onFilterYearChange={onFilterYearChange}
                    emissionFactorTableId={emissionFactorTableId}
                    yearFilter={yearFilter}
                    valueSearch={searchTerm}
                    setValueSearch={onSearchChange}
                    onCleanFilter={onCleanFilter}
                    typeData={typeDataFilter}
                    onFilterTypeData={onFilterTypeData}
                />
            </div>

            <Grid item xs={12} style={{ marginTop: heightGroupFilter }}>
                <div className={classes.tableSection}>
                    <span>
                        全{totalFactorTableId.toLocaleString('en-US')}件中
                        {limit > totalFactorTableId ? totalFactorTableId : limit}
                        件を表示中
                    </span>
                    <div className={classes.tableSection}>
                        表示件数：&nbsp;
                        <SelectField
                            size="small"
                            fullWidth={false}
                            style={{ minWidth: 65, fontSize: 16 }}
                            menuItems={limitOptions}
                            value={limit}
                            onChange={(e) => setLimit(e.target.value as number)}
                        />
                        &nbsp;件
                    </div>
                </div>
                <Space height={15} />
                <Box style={{ width: '100%' }}>
                    <EmissionFactorIdTable
                        listEmissionFactorTableId={sortData}
                        handleSort={handleSort}
                        sortMode={sortMode}
                        sortId={sortId}
                        typeDataFilter={typeDataFilter}
                        loading={loading}
                        onEditClick={handleEditClick}
                    />
                    <div className={classes.divPagination}>
                        {totalFactorTableId > limit && (
                            <CustomPagination2
                                page={page}
                                setPage={setPage}
                                rowsPerPage={limit}
                                total={totalFactorTableId}
                            />
                        )}
                    </div>
                </Box>
            </Grid>
        </div>
    )
}

export default withLayout(EmissionFactorList)
